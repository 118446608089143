<template>
  <image-picker
    :src="src"
    :name="name"
    :ratio="ratio"
    @choose="choose"
    @remove="remove"
    :crop="crop"
  />
</template>

<script>
import ImagePicker from '@/components/elements/image-picker.vue'

export default {
  components: {
    ImagePicker,
  },
  model: {
    prop: 'src',
    event: 'input',
  },
  props: {
    crop: {
      type: String
    },
    name: {
      type: String
    },
    ratio: {
      type: [Number, Function],
    },
    src: {
      type: String
    },
  },
  methods: {
    remove () {
      if (!confirm('Удалить?')) return
      this.$emit('input', null)
    },
    choose () {
      this
        .openPhotoModal()
        .$once('choose', (photo) => {
          this.$emit('input', photo)
        })
    }
  }
}
</script>
