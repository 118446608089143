<template>
  <div>
    <div class="d-flex mb-8">
      <h2 class="text-h4">
        Pinned links
      </h2>

      <v-btn @click="() => save.run()" class="ml-auto" color="success">Save</v-btn>
    </div>

    <pinned-links v-model="req.data" />
  </div>
</template>

<script>
import PinnedLinks from '@/components/PinnedLink/PinnedLinks.vue'
import service from '@/services/pinned-links'
import { createRequest } from '@/utils/requestsFactory'

export default {
  components: {
    PinnedLinks,
  },
  mounted () {
    return this.req.run()
  },
  data () {
    return {
      req: createRequest(async () => {
        const { items } = await service.getAll()
        return items
      }, []),
      save: createRequest(() => service.update(this.req.data))
    }
  },
}
</script>
