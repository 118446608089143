<template>
  <list-editor-light
    v-bind="{ ...$attrs, items: value }"
    v-on="$listeners"
  >
    <template #content="{ idx, item, update }">
      <pinned-link-item
        v-bind="item"
        @toggle="() => update(idx, { ...item, enabled: !item.enabled })"
      />
    </template>
    <template #dialog="{ input, model }">
      <pinned-link :value="model" @input="input" />
    </template>
  </list-editor-light>
</template>

<script>
import PinnedLink from './PinnedLink.vue'
import ListEditorLight from '@/components/ListEditor/ListEditorLight.vue'
import PinnedLinkItem from './PinnedLinkItem.vue'

export default {
  components: {
    PinnedLinkItem,
    PinnedLink,
    ListEditorLight
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  }
}
</script>
