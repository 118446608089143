<template>
  <div>
    <v-row>
      <v-col cols="2">
        <validation-provider slim name="image" rules="required" v-slot="{ errors }">
          <div>
            <image-select-new
              crop="original"
              :ratio="1.3125"
              name="image"
              rules="required"
              :src="form.imageUrl"
              @input="onImageSelect"
            />
            <div v-if="errors.length" class="red--text">{{ errors[0] }}</div>
          </div>
        </validation-provider>
        <v-btn
          class="mt-4"
          block
          small
          :color="form.enabled ? 'success' : ''"
          @click="form.enabled = !form.enabled"
        >
          <v-icon small left>{{ form.enabled ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
          {{ form.enabled ? 'Активен' : 'Скрыт' }}
        </v-btn>
      </v-col>
      <v-col>
        <locale-field
          autofocus
          name="title"
          component="v-text-field"
          v-model="form.title"
          dense
          rules="required"
          label="Title"
        />
        <locale-field
          class="mt-8"
          name="text"
          component="v-text-field"
          v-model="form.text"
          dense
          rules="required"
          label="Text"
        />

        <v-row>
          <v-col>
            <v-text-field
              class="mt-8"
              label="URL"
              v-model="form.url"
            />
          </v-col>
          <v-col>
            <v-text-field
              class="mt-8"
              label="Метка для трекинга"
              v-model="form.goal"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
</div>
</template>

<script>
import isEqual from 'lodash/isEqual'
import PinnedLink from '@/models/PinnedLink'
import LocaleField from '@/components/Locale/LocaleField.vue'
import ImageSelect from '@/components/elements/image-select.vue'
import ImageSelectNew from '@/components/elements/image-select-new.vue'

export default {
  components: {
    ImageSelectNew,
    ImageSelect,
    LocaleField,
  },
  props: {
    value: {
      type: Object,
    }
  },
  data () {
    return {
      form: new PinnedLink(this.value),
    }
  },
  watch: {
    form: {
      deep: true,
      handler (form) {
        this.$emit('input', form)
      }
    },
    value: {
      deep: true,
      handler (value) {
        const model = new PinnedLink(value)
        if (isEqual(model, this.form)) return
        this.form = model
      }
    }
  },
  methods: {
    onImageSelect (image) {
      const { id, url } = image || {}
      this.form.imageId = id
      this.form.imageUrl = url
    }
  }
}
</script>
