import { v4 as uuid } from 'uuid'

export default class PinnedLink {
  constructor (options) {
    const {
      id = uuid(),
      title = null,
      text = null,
      url = null,
      imageId = null,
      imageUrl = null,
      enabled = false,
      goal = null,
    } = options || {}
    return {
      id,
      title,
      text,
      url,
      imageId,
      imageUrl,
      enabled,
      goal,
    }
  }
}
