var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('list-editor-light',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"content",fn:function(ref){
var idx = ref.idx;
var item = ref.item;
var update = ref.update;
return [_c('pinned-link-item',_vm._b({on:{"toggle":function () { return update(idx, Object.assign({}, item, {enabled: !item.enabled})); }}},'pinned-link-item',item,false))]}},{key:"dialog",fn:function(ref){
var input = ref.input;
var model = ref.model;
return [_c('pinned-link',{attrs:{"value":model},on:{"input":input}})]}}])},'list-editor-light',Object.assign({}, _vm.$attrs, {items: _vm.value}),false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }