<template>
  <div class="pinned-link-item">
    <div class="pinned-link-item__aside">
      <img
        :src="imageUrl"
        class="pinned-link-item__image"
        style="max-width: 42px;"
      />
    </div>
    <div class="pinned-link-item__content">
      <v-btn
          @click="$emit('toggle')"
          :color="enabled ? 'success' : ''"
          x-small
          depressed
          class="mb-1"
        >
        <v-icon x-small left>{{ enabled ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
        <span>{{ enabled ? 'Активен' : 'Скрыт' }}</span>
      </v-btn>
      <locale-text :value="title" onlyLocale="ru" class="font-weight-bold" />
      <locale-text :value="text" onlyLocale="ru" />

      <div class="mt-1" style="opacity: 0.55;">
        <v-chip small v-if="url" class="mr-2">{{ url }}</v-chip>
        <v-chip small v-if="goal" class="mr-2">Метка: {{ goal }}</v-chip>
      </div>

    </div>
  </div>
</template>

<script>
import LocaleText from '@/components/Locale/LocaleText.vue'

export default {
  components: {
    LocaleText
  },
  props: {
    title: {
      type: Object
    },
    text: {
      type: Object
    },
    url: {
      type: String
    },
    imageUrl: {
      type: String
    },
    enabled: {
      type: Boolean,
    },
    goal: {
      type: String,
    }
  }
}
</script>

<style lang="scss" scoped>
.pinned-link-item {
  display: flex;
  align-items: flex-start;
  grid-gap: 12px;
}
.pinned-link-item__aside {
  flex: 0 0 auto;
  width: 42px;
}
.pinned-link-item__image {
}
</style>
