<template>
  <div class="image-picker">
    <div
      class="image-picker__ratio"
      :style="{ paddingBottom }"
    />
    <div
      :style="{ backgroundImage: `url(${src})` }"
      v-if="src"
      class="image-picker__image"
      :class="{
        [`image-picker__image--crop-${crop}`]: true
      }"
    />
    <div class="image-picker__actions">
      <v-btn
        fab
        depressed
        v-for="action in actions"
        :key="action.key"
        :title="action.title"
        @click="action.click"
        x-small
      >
        <v-icon small>{{ action.icon }}</v-icon>
      </v-btn>
    </div>

  </div>
</template>

<script>
export const CROP_COVER = 'cover'
export const CROP_CONTAIN = 'contain'
export const CROP_ORIGINAL = 'original'
export const crops = { CROP_CONTAIN, CROP_COVER, CROP_ORIGINAL }

export default {
  props: {
    src: {
      type: String
    },
    crop: {
      type: String,
      default: CROP_COVER,
      validator: (prop) => Object.values(crops).includes(prop)
    },
    ratio: {
      type: [Number, Function],
      default: 0.75
    }
  },
  computed: {
    paddingBottom () {
      let value
      if (typeof this.ratio === 'function') {
        value = this.ratio()
      } else if (typeof this.ratio === 'number') {
        value = this.ratio
      }
      return value !== undefined ? (value * 100) + '%' : null
    },
    actions () {
      return [
        {
          key: 'choose',
          title: 'Выбрать',
          icon: 'mdi-pencil',
          click: () => this.$emit('choose')
        },
        {
          key: 'remove',
          title: 'Удалить',
          icon: 'mdi-close',
          click: () => this.$emit('remove')
        }
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.image-picker {
  position: relative;
  max-width: 100%;
  background-color: #eee;
  overflow: hidden;
}

.image-picker__ratio {
  display: block;
}

.image-picker__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.image-picker__image--crop-cover {
  background-size: cover;
}

.image-picker__image--crop-contain {
  background-size: contain;
}
.image-picker__image--crop-original {
}


.image-picker:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.5))
}

.image-picker__actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}
</style>
