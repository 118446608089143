<template>
  <image-picker
    :src="src"
    :ratio="ratio"
    @choose="choose"
    @remove="remove"
  />
</template>

<script>
import ImagePicker from '@/components/elements/image-picker.vue'

export default {
  components: {
    ImagePicker,
  },
  props: {
    ratio: {
      type: [Number, Function],
    },
    value: {
      type: Object
    }
  },
  computed: {
    src () {
      return this.value?.url
    }
  },
  methods: {
    remove () {
      if (!confirm('Удалить?')) return
      this.$emit('input', null)
    },
    choose () {
      this
        .openPhotoModal()
        .$once('choose', (photo) => {
          this.$emit('input', photo)
        })
    }
  }
}
</script>
