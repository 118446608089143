import BaseService from './baseService'
import api from '../utils/api'

class PinnedLinksService extends BaseService {
  get url () {
    return 'pinned-links'
  }

  update (items) {
    return api.patch(this.url, { items })
  }
}

export default new PinnedLinksService()

